var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-tree", {
        attrs: {
          checkable: true,
          "expanded-keys": _vm.expandedKeys,
          "tree-data": _vm.treeData,
        },
        on: { expand: _vm.onExpand, check: _vm.onCheck },
        scopedSlots: _vm._u([
          {
            key: "custom",
            fn: function (item) {
              return [
                item.identification === "dep"
                  ? _c("div", { staticClass: "tree_box" }, [
                      _c("div", [
                        _c("img", {
                          staticClass: "icon",
                          attrs: { src: _vm.imgBase, alt: "" },
                        }),
                      ]),
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.orgName)),
                      ]),
                    ])
                  : _c(
                      "div",
                      { staticClass: "tree_box" },
                      [
                        _c("CbAvater", {
                          attrs: {
                            "on-job-id": item.onJobId,
                            "avater-name": item.staffName,
                            size: "30",
                          },
                        }),
                        _c("div", { staticClass: "jobNumber" }, [
                          _vm._v(_vm._s(item.phoneNum)),
                        ]),
                      ],
                      1
                    ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.checkedKeys,
          callback: function ($$v) {
            _vm.checkedKeys = $$v
          },
          expression: "checkedKeys",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }