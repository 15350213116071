var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c(
        "a-input",
        {
          attrs: { placeholder: _vm.placeholder },
          on: {
            focus: _vm.focus,
            blur: _vm.blur,
            pressEnter: _vm.searchValueChange,
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        [
          _c("a-icon", {
            attrs: { slot: "prefix", type: "search" },
            slot: "prefix",
          }),
        ],
        1
      ),
      _vm.valueIcon
        ? _c(
            "div",
            { staticClass: "icon", on: { click: _vm.visibleCallback } },
            [
              _c("a-icon", {
                attrs: { type: "close-circle", theme: "filled" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }