var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "department_top" }, [
      _c("div", [
        _c("span", [_vm._v("已选：")]),
        _c("span", { staticClass: "department_top_margin" }, [
          _vm._v(
            "人员" +
              _vm._s(_vm.menuListData ? _vm.menuListData.length : 0) +
              "个"
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "clear_all", on: { click: _vm.delAllCallBack } },
        [_vm._v("全部清空")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "department_count" },
      _vm._l(_vm.menuListData, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "department_count_data" },
          [
            _c(
              "div",
              { staticClass: "department_count_datas" },
              [
                _c(
                  "div",
                  { staticClass: "department_count_info" },
                  [
                    _c("CbAvater", {
                      attrs: {
                        "on-job-id": item.onJobId,
                        "avater-name": item.staffName,
                        size: "30",
                      },
                    }),
                    _c("div", { staticClass: "jobNumber" }, [
                      _vm._v(_vm._s(item.phoneNum)),
                    ]),
                  ],
                  1
                ),
                _c("a-icon", {
                  staticClass: "filled_icon",
                  attrs: { type: "close-circle", theme: "filled" },
                  on: {
                    click: function ($event) {
                      return _vm.delCallBack(item.staffId)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "button" },
      [
        _c(
          "a-button",
          { staticClass: "button_close", on: { click: _vm.closeModel } },
          [_vm._v("取消")]
        ),
        _c(
          "a-button",
          { attrs: { type: "primary" }, on: { click: _vm.perModelConfirm } },
          [_vm._v("确认")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }